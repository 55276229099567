import React from 'react';
import { Empty } from 'antd';
import classnames from 'classnames';
import '../assets/scss/course-list-empty.scss';
import empty from '../assets/image/empty.png';
function CourseListEmpty({ className, image, description }) {
  return (
    <Empty
      className={classnames('empty-wrapper', className)}
      image={image}
      description={description}
    />
  );
}

export default CourseListEmpty;

CourseListEmpty.defaultProps = {
  description: <span className="text">未找到相关内容</span>,
  image: empty
};
