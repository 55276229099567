import React from 'react';
import Item from './Item';
import '../assets/scss/course-list.scss';
const CourseList = ({ list, userCourseList }) => {
  return (
    <div className="course-list">
      {list.map(item => {
        const userCourse = userCourseList.find(
          course => course.courseId === item.courseId
        );
        return <Item {...item} {...userCourse} key={item.courseId} />
      })}
    </div>
  );
};

export default CourseList;
