import * as React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Introduce from '../components/Introduce';
import CourseList from '../components/CourseList';
import CourseListEmpty from '../components/CourseListEmpty';
import '../assets/scss/home.scss';
import {} from '../state/actions';
import { httpPost } from '../config/fetch';
import { getCourseList } from '../config/api';
const IndexPage = ({ userCourseList }) => {
  const [courseList, setCourseList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [pageInfo] = React.useState({
    pageNumber: 1,
    pageSize: 3
  });
  React.useEffect(() => {
    const getData = () => {
      setLoading(true);
      httpPost(getCourseList, pageInfo)
        .then(res => {
          const { resCode, courseList } = res;
          if (resCode === 0) {
            setCourseList(courseList);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setCourseList([]);
        });
    };
    getData();
  }, [pageInfo]);
  return (
    <Layout>
      <SEO title="首页" />
      <div className="home-wrapper">
        <div className="container">
          <Introduce />
          <Spin spinning={loading} size="large">
            {courseList.length > 0 ? (
              <CourseList list={courseList} userCourseList={userCourseList} />
            ) : (
              <CourseListEmpty />
            )}
          </Spin>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    userCourseList: state.userCourseList
  };
};

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
