import React from 'react';
import { Link } from 'gatsby';
import '../assets/scss/item.scss';
import { EXCHANGE_TYPE } from '../utils';
function Item({
  courseId,
  courseName,
  introduction,
  thumbnail,
  price,
  method
}) {
  return (
    <Link className="item-wrapper" to={`/course-detail/?${courseId}`}>
      <img className="image" src={thumbnail} alt="" />
      <div className="desc">
        <p className="title">{courseName}</p>
        <p className="text">{introduction}</p>
        {method === EXCHANGE_TYPE ? (
          <div className="conver">
            <p>已兑换</p>
          </div>
        ) : (
          <div className="price">
            <p>
              <span className="unit">￥</span>
              <span>{price}</span>
            </p>
          </div>
        )}
      </div>
    </Link>
  );
}

export default Item;
